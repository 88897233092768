import React from "react"
import { Box, Typography } from "@material-ui/core"
import SEO from "../components/seo"
import Wrapper from "../components/Wrapper"
import Headline from "../components/Headline"
import Navbar from "../components/Navbar"
import Heading from "../components/Heading"
import SubCategoryList from "../components/CategoryListTag"
import ProductList from "../components/ProductList"
import Footer from '../components/Footer'

import SampleImage from "../images/sample-img.png"
import SampleImage2 from "../images/sample-img-2.png"

const subCategories = [
  {
    name: "toilet soaps",
    images: SampleImage,
    href: "/products/detail/toilet-soaps",
  },
  {
    name: "multipurpose soap",
    images: SampleImage,
    href: "/products/detail/multipurpose-soap",
  },
  {
    name: "laundry soaps",
    images: SampleImage,
    href: "/products/detail/laundry-soaps",
  },
  { name: "cooking oil", images: SampleImage, href: "/products/cooking-oil" },
]

// Contoh Render Konten Markup HTML, tergantung return dari CMS nya apa
const markupHtmlExample='<p>Halo Semuanya! Kenalkan namaku <strong>curapalm</strong>.</p>'
// Ini diambil dari localstorage web cura (return dari flamelink)
const markupHtmlExampleTwo = '<p><span style="color: rgb(0,0,0);font-family: Roboto;"><strong>PC/Carton :</strong></span><br><span style="color: rgb(0,0,0);font-family: Roboto;">75gr x 72 pcs/ Carton</span><br><span style="color: rgb(0,0,0);font-family: Roboto;">1 x 20 ft Load 2300 Cartons</span><br><span style="color: rgb(0,0,0);font-family: Roboto;">1 x 40 ft Load 4750 Cartons</span></p>↵<p><br><span style="color: rgb(0,0,0);font-family: Roboto;"><strong>6 Variants available :</strong></span><br><span style="color: rgb(0,0,0);font-family: Roboto;">Rose, Marine, Lavender, Lemon</span><br><span style="color: rgb(0,0,0);font-family: Roboto;">Zest, Pearl &amp; Carrot </span><br>&nbsp;</p>'
const RenderHTML= content =>{
    return(
      <Box dangerouslySetInnerHTML={ {__html: content} } />
    )
}

function makeSlug(string) {
  return string
    .toLowerCase()
    .replace(/[^\w ]+/g,'')
    .replace(/ +/g,'-')
}


const ProductDetails = ({ pageContext }) => {
  // console.log(pageContext);
  let listProduct = []
  if (pageContext.listProduct && pageContext.listProduct.length != 0 ){
    pageContext.listProduct.map(prod => {
      const obj = {
        name: prod.namaBarang,
        images: prod.gambarProduct.url,
        subCategory: prod.subCategory.namaSubKategori,
        description: '',
        content: RenderHTML(prod.deskripsi),
      }
      listProduct.push(obj)
    })
  }
  let listSubCat = []
  if (pageContext.subCategory && pageContext.subCategory.length != 0){
    pageContext.subCategory.map(subCat => {
      const slug = makeSlug(subCat.namaSubKategori)
      let subkateg = subCat.namaSubKategori.toLowerCase()
      subkateg = subkateg.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase())  
      const obj = {
        name: subkateg,
        // images: SampleImage,
        href: `/products/detail/${slug}`,
      }
      listSubCat.push(obj)
    })
  }
  return (
    <Wrapper>
      <SEO title="Product Details" />
      <Headline />
      <Navbar />

      {/* minHeight: 300px */}
      <Heading minHeight="18.75rem">
        {/* Title Heading Area*/}
        <Box
          className="heading-text-wrapper"
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={5}
        >
          <Box>
            <Typography variant="h1">{pageContext.category}</Typography>
          </Box>
        </Box>
        {/* Category List Tag Area */}
        <Box
          className="category-list-wrapper"
          width="95%"
          display="flex"
          flexWrap="wrap"
          justifyContent="flex-start"
          alignItems="center"
        >
          <SubCategoryList categories={listSubCat} linkPage={pageContext.pathPage}/>
        </Box>
      </Heading>
      <Box
        className="card-list-wrapper"
        display="flex"
        justifyContent="center"
        paddingY="5.625rem"
      >
        <ProductList products={listProduct} />
      </Box>
      <Footer data={pageContext.footer} />
    </Wrapper>
  )
}

export default ProductDetails
